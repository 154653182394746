import axios from 'axios';

export default {
  getExecutionsWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/Executions', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addExecution: (execution) => axios.post('/api/Executions', execution),
  updateExecution: (execution) => axios.put(`/api/Executions/${execution.id}`, execution),
  deleteExecution: (id) => axios.delete(`/api/Executions/${id}`),  
};
