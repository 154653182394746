import axios from 'axios';

export default {
  getStaffCategoriesWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/StaffCategories', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addStaffCategory: (staffCategory) => axios.post('/api/StaffCategories', staffCategory),
  updateStaffCategory: (staffCategory) => axios.put(`/api/StaffCategories/${staffCategory.id}`, staffCategory),
  deleteStaffCategory: (id) => axios.delete(`/api/StaffCategories/${id}`), 
};
