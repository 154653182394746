import axios from 'axios';

export default {
  getMisoperationReasonsWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/MisoperationReasons', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addMisoperationReason: (misoperationReason) => axios.post('/api/MisoperationReasons', misoperationReason),
  updateMisoperationReason: (misoperationReason) => axios.put(`/api/MisoperationReasons/${misoperationReason.id}`, misoperationReason),
  deleteMisoperationReason: (id) => axios.delete(`/api/MisoperationReasons/${id}`), 
};

